<template>
  <transition name="fade">
    <div v-if="show" class="modal">
      <div class="modal__backdrop" @click="closeModal()" />

      <div class="modal__dialog rounded-lg">
        <div class="modal__body rounded-lg">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const rootSelector = "body";

export default {
  name: "BModal",

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    show(newVal) {
      if (newVal === true) {
        document.querySelector(rootSelector).classList.add("overflow-hidden");
      } else {
        document
          .querySelector(rootSelector)
          .classList.remove("overflow-hidden");
      }
    }
  },

  methods: {
    closeModal() {
      this.$emit("update:show", false);
    },
    openModal() {
      this.$emit("update:show", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    position: relative;
    width: 100%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }
  &__body {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 600px;
    margin: 0 auto;

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
