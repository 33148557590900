<template>
  <nav
    class="
      px-6
      flex
      items-center
      justify-between
      flex-wrap
      bg-white
      border-b-2 border-gray-200
      h-20
      shadow-sm
    "
    :class="[fixed ? 'fixedNav' : 'staticNav']"
    :style="{
      'background-color': navbarColor,
      'border-color': navbarColor
    }"
  >
    <div class="container mx-auto flex items-center">
      <router-link :to="{ path: '/' }" class="block">
        <img v-if="logo" :src="logo" :alt="websiteName" class="h-10" />
        <span class="font-semibold text-lg text-gray-800 py-6" v-else>
          {{ websiteName }}
        </span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TheNavbar",
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false
    };
  },

  computed: {
    logo() {
      return this.$store.state.config.logo;
    },
    websiteName() {
      return this.$store.state.config.name;
    },
    navbarColor() {
      return this.$store.state.config?.theme?.navbarColor;
    }
  }
};
</script>

<style lang="scss" scoped>
.fixedNav {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
