<template>
  <div class="checkout-form width-full">
    <div class="bg-white px-4 pt-5 pb-4 sm:p-6">
      <GmapMap
        :center="{ lat: center.latitude, lng: center.longitude }"
        :zoom="13"
        :options="{
          mapTypeControl: false,
          streetViewControl: false
        }"
        @click="mark"
        map-type-id="terrain"
        class="map"
      >
        <google-marker v-if="marker" :position="marker"></google-marker>
      </GmapMap>
      <div
        class="px-4 py-4 sm:py-3 text-right sm:px-6 sm:flex sm:flex-row-reverse"
      >
        <b-button
          @click="accept"
          variant="primary"
          class="w-full sm:w-auto | mb-3 sm:mb-0 sm:mt-0 text-white"
        >
          <div>{{ $t("continue") }}</div>
        </b-button>

        <b-button
          @click="cancel"
          variant="secondary"
          class="w-full sm:w-auto | sm:mr-3"
        >
          <div>{{ $t("cancel") }}</div>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BButton from "../BButton";
import * as mutation from "@/store/modules/checkout/mutation-types";
const configModule = "config";

export default {
  name: "CheckoutMap",

  components: {
    BButton
  },

  data() {
    return {
      errors: {},
      center: {
        latitude: 0,
        longitude: 0
      },
      marker: null
    };
  },
  computed: {},
  methods: {
    getLocation() {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          this.center = {
            latitude,
            longitude
          };
        }
      );
    },
    mark({ latLng: { lat, lng } }) {
      this.marker = {
        lat: lat(),
        lng: lng()
      };
    },
    cancel() {
      this.closeMap();
    },
    accept() {
      this.closeMap({ ...this.marker });
    }
  },
  mounted() {
    this.marker = this.markerSelected;
    if (!this.marker) this.getLocation();
    else
      this.center = {
        latitude: this.marker.lat,
        longitude: this.marker.lng
      };
  },
  props: ["closeMap", "markerSelected"]
};
</script>
<style lang="scss" scoped>
.map {
  width: 100%;
  height: 500px;
}
</style>
