<template>
  <div
    class="layout-main"
    :class="{ 'bg-gray-100': !isWidget }"
    :style="containerStyles"
  >
    <the-navbar v-if="!isWidget" :fixed="fixedNav" />
    <div class="router-wrapper fixedMain">
      <router-view />
    </div>
    <the-footer
      v-if="showFooter || showBrandedFooter"
      :branded="showBrandedFooter"
    />
    <biew-badge v-if="showBiewBadge" />
    <shopping-cart v-if="showShoppingCart" />
    <product-options-card />
    <pwa-prompt-ios />
  </div>
</template>

<script>
import BiewBadge from "@/components/BiewBadge";
import ProductOptionsCard from "@/components/ProductOptionsCard";
import ShoppingCart from "@/components/ShoppingCart/ShoppingCart";
import TheFooter from "@/components/TheFooter";
import TheNavbar from "@/components/TheNavbar";
import PwaPromptIos from "@/components/PwaPromptIos";

export default {
  name: "Main",

  components: {
    BiewBadge,
    ProductOptionsCard,
    PwaPromptIos,
    ShoppingCart,
    TheFooter,
    TheNavbar
  },

  computed: {
    containerStyles() {
      const styles = {};

      if (this.backgroundColor && !this.isWidget) {
        styles.backgroundColor = this.backgroundColor;
      }
      return styles;
    },
    backgroundColor() {
      return this.$store.state.config?.theme?.backgroundColor;
    },
    config() {
      return this.$store.state.config.listView;
    },
    isDemo() {
      return this.$store.state.config.demo;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    isPro() {
      return this.$store.state.config.isPro;
    },
    isWidget() {
      return process.env.VUE_APP_MODE === "widget";
    },
    showBiewBadge() {
      return (!this.isPro || this.showBranding) && !this.isLoading;
    },
    showBranding() {
      return this.$store.state.config.showBranding ? true : false;
    },
    showFooter() {
      if (this.$store.state.config?.footer) {
        return true;
      }
      return false;
    },
    showBrandedFooter() {
      return ((!this.isPro && !this.isWidget && !this.isDemo) ||
        (!this.isWidget && this.showBranding && !this.isDemo)) &&
        !this.isLoading
        ? true
        : false;
    },
    showShoppingCart() {
      return this.$store.getters["isShoppingCartEnabled"] && !this.isLoading;
    },
    fixedNav() {
      return this.$store.state.config?.fixedNav || false;
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  .router-wrapper {
    box-sizing: border-box;
    min-height: 93vh;
  }
}
</style>
