<template>
  <div>
    <b-modal
      :show.sync="showProductOptionsModal"
      class="w-full"
      v-if="config && selectedItem && productOptions"
    >
      <b-content-card
        :item="selectedItem"
        :config="config"
        :cta-action="addToCart"
        :show-custom-fields="false"
      >
        <template #bottom-content>
          <div>
            <div
              v-for="(productOption, index) in productOptions"
              :key="`product-option-${index}`"
              class="mt-4"
            >
              <b-single-option-selection
                v-if="
                  productOption.type === VariantTypes.NUM_SINGLE_CHOICE &&
                    selectedOptions[index]
                "
                :name="productOption.name"
                :options="productOption.options"
                :item="selectedItem"
                :required="productOption.required"
                :value="selectedOptions[index].selected"
                @input="handleChange($event, index)"
              />
              <b-multiple-option-selection
                v-else-if="selectedOptions[index]"
                :name="productOption.name"
                :options="productOption.options"
                :item="selectedItem"
                :required="productOption.required"
                :value="selectedOptions[index].selected"
                @input="handleChange($event, index)"
              />
            </div>
            <hr class="my-4" />
            <div>
              <div class="font-bold text-lg">{{ formattedPrice }}</div>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="d-flex">
            <b-button
              variant="secondary"
              expanded
              class="mt-3 uppercase font-medium sm:text-base"
              @click="hide"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-content-card>
    </b-modal>
    <div class="z-20 top-3 left-3 fixed">
      <transition-group name="alert">
        <b-notification
          v-for="(notification, index) in notifications"
          :key="`notification-${index}`"
          :message="notification.message"
          :duration="notification.duration"
          @hide="removeNotification(index)"
        >
        </b-notification>
      </transition-group>
    </div>
  </div>
</template>

<script>
import BButton from "./BButton";
import BContentCard from "./BContentCard";
import BModal from "./BModal";
import BMultipleOptionSelection from "./BMultipleOptionSelection";
import BNotification from "./BNotification";
import BSingleOptionSelection from "./BSingleOptionSelection";
import VariantTypes from "@/constants/checkout/variant-type";
import * as CheckoutMutation from "@/store/modules/checkout/mutation-types";
import money from "@/utils/money";

const moduleName = "config";
const listConfig = "listView";
const productOptions = "productOptions";

export default {
  name: "ProductOptionsCard",

  components: {
    BButton,
    BContentCard,
    BModal,
    BMultipleOptionSelection,
    BNotification,
    BSingleOptionSelection
  },

  data() {
    return {
      VariantTypes,
      price: this.initialPrice,
      notifications: [],
      selectedOptions: []
    };
  },

  computed: {
    config() {
      return this.$store.state[moduleName][listConfig];
    },
    productOptions() {
      return this.$store.state[moduleName][productOptions] || [];
    },
    selectedItem() {
      return this.$store.state.checkout.selected;
    },
    showProductOptionsModal: {
      get() {
        return this.$store.state.checkout.showProductOptionsModal;
      },
      set(val) {
        this.$store.commit(
          `checkout/${CheckoutMutation.SHOW_PRODUCT_OPTIONS_MODAL}`,
          val
        );
      }
    },
    formattedPrice() {
      return money.format(this.price);
    },
    priceField() {
      return this.$store.state[moduleName].checkout.priceField;
    },
    initialPrice() {
      const priceFieldValue = this.selectedItem[this.priceField];
      const price = priceFieldValue ? money.parse(priceFieldValue) : 0;
      return price;
    }
  },

  watch: {
    showProductOptionsModal(show) {
      this.reset();

      if (show === true) {
        this.$store.dispatch("checkout/setMoneyFormat", this.selectedItem);
      }
    }
  },

  methods: {
    parsedOptions() {
      return this.productOptions.map(op => ({
        ...op,
        selected: op.type === VariantTypes.NUM_SINGLE_CHOICE ? null : []
      }));
    },
    addToCart() {
      const notifications = this.getInvalidNotifications();
      if (!notifications.length) {
        this.$store
          .dispatch("checkout/addToCart", {
            ...this.selectedItem,
            variants: this.selectedOptions
          })
          .then(() => {
            this.showProductOptionsModal = false;
          });
      } else {
        this.notifications = [...notifications];
      }
    },
    getInvalidNotifications() {
      const notifications = [];

      this.selectedOptions.forEach(variant => {
        if (variant.required && this.hasVariantOptions(variant.options)) {
          if (
            (variant.type === VariantTypes.NUM_SINGLE_CHOICE &&
              !variant.selected) ||
            (variant.type === VariantTypes.NUM_MULTIPLE_CHOICE &&
              !variant.selected.length)
          ) {
            notifications.push({
              message: `You must choose a ${variant.name}`,
              duration: 2000
            });
          }
        }
      });
      return notifications;
    },
    hasVariantOptions(options) {
      return options.some(op => {
        if (this.selectedItem[op.name]) {
          return true;
        }
        return false;
      });
    },
    handleChange(value, index) {
      const newOption = { ...this.selectedOptions[index], selected: value };
      this.selectedOptions.splice(index, 1, newOption);

      let itemTotal = money.currency(this.initialPrice);
      this.selectedOptions.forEach(variant => {
        if (
          variant.type === VariantTypes.NUM_SINGLE_CHOICE &&
          variant.selected
        ) {
          itemTotal = itemTotal.add(money.parse(variant.selected.value));
        } else if (
          variant.type === VariantTypes.NUM_MULTIPLE_CHOICE &&
          variant.selected.length
        ) {
          variant.selected.forEach(multChoice => {
            itemTotal = itemTotal.add(money.parse(multChoice.value));
          });
        }
      });
      this.price = itemTotal.value;
    },
    hide() {
      this.showProductOptionsModal = false;
    },
    removeNotification(index) {
      this.notifications.splice(index, 1);
    },
    reset() {
      this.price = this.initialPrice;
      this.notifications = [];
      this.selectedOptions.splice(0);
      this.parsedOptions().forEach(option => {
        this.selectedOptions.push(option);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.alert-enter-active,
.alert-leave-active {
  transition: all 1s;
}
.alert-enter,
.alert-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
