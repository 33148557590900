<template>
  <transition name="fade">
    <div
      v-if="showInstallMessage"
      class="absolute inset-0 z-20 h-screen w-screen bg-gray-900 bg-opacity-25 flex justify-center items-center shadow-lg p-4"
    >
      <div
        class="py-4 px-8 bg-white w-96 text-center rounded font-apple-system"
      >
        <div class="flex justify-center">
          <img
            class="rounded border w-16 h-16 -mt-8"
            src="/img/icons/apple-touch-icon-60x60.png"
            alt=""
          />
        </div>
        <div class="mt-4 text-xl text-gray-700">Install {{ name }}</div>
        <div class="text-gray-400">
          Install this application on your homescreen for a better experience.
        </div>
        <div
          class="text-gray-500 text-sm flex justify-center items-center mt-4"
        >
          <span>Tap</span>
          <img src="@/assets/img/share-icon-iphone.jpeg" class="w-4 mx-2" />
          <span>then "Add to Home Screen"</span>
        </div>
        <div
          @click="showInstallMessage = false"
          role="button"
          class="p-4 mt-4 uppercase text-blue-400"
        >
          Close
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "PwaPromptIos",

  computed: {
    name() {
      return this.$store.state.config?.pwa?.name;
    },
    showInstallMessage: {
      get() {
        return this.$store.state.showInstallMessage;
      },
      set(val) {
        this.$store.commit("SET_SHOW_INSTALL_MESSAGE", val);
      }
    }
  },

  watch: {
    showInstallMessage: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          document.querySelector("body").classList.add("overflow-hidden");
        } else {
          document.querySelector("body").classList.remove("overflow-hidden");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.font-apple-system {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
