import axios from "axios";

const model = "sheets";

export default {
  getSheetItems(sheetId, params) {
    return axios.get(`/api/${model}/${sheetId}/items`, params);
  },
  addItems(sheetId, obj) {
    return axios.post(`/api/${model}/${sheetId}/addItems`, obj);
  }
};
