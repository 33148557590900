<template>
  <div
    @click.self="toggle"
    :class="{
      show: showShoppingCart,
      'pointer-events-none': !showShoppingCart
    }"
    class="shopping-cart fixed z-20 w-full h-full flex flex-col items-end transition duration-300 ease-in-out"
  >
    <div class="flex justify-end pointer-events-auto relative">
      <div
        v-show="!isEmpty && !showShoppingCart"
        class="absolute top-0 right-0"
      >
        <span class="flex h-3 w-3 relative">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-900 opacity-75"
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3 bg-gray-800"
          ></span>
        </span>
      </div>
      <div
        @click.stop="toggle"
        class="bg-white h-12 w-12 text-gray-800 rounded-full transition-shadow duration-300 text-center shadow-md hover:shadow-xl flex justify-center items-center cursor-pointer border border-gray-100 pointer-events-auto outline-none"
      >
        <b-icon
          v-show="!showShoppingCart"
          name="shopping-cart"
          class="transition duration-300 ease-in-out pointer-events-auto"
        />
        <b-icon
          v-show="showShoppingCart"
          name="times"
          size="lg"
          class="transition duration-300 ease-in-out pointer-events-auto"
        />
      </div>
    </div>
    <div
      v-show="showShoppingCart"
      class="shopping-list bg-white shadow-md border border-gray-100 text-gray-800 mt-6 pointer-events-auto rounded-lg overflow-y-auto pt-4 w-full sm:w-96 transition duration-300 ease-in-out"
    >
      <div v-show="isEmpty" class="px-4 pb-4 text-center">
        <div>{{ $t("cart_is_empty") }}</div>
      </div>
      <div v-show="!isEmpty">
        <div class="border-b overflow-auto">
          <shopping-cart-item
            v-for="(item, index) in items"
            :key="`item-${item.id}-${index}`"
            :item="item"
            :amount="item.amount"
            :image="item.image"
            :name="item.name"
            :price="item.price"
          />
        </div>
        <div class="p-4 bg-gray-100">
          <div class="flex justify-between mb-3">
            <div class="font-semibold">Total</div>
            <div>{{ formattedTotal }}</div>
          </div>
          <b-button @click="showCheckoutForm = true" class="w-full">
            {{ $t("checkout") }}
          </b-button>
        </div>
      </div>
    </div>
    <b-modal :show.sync="showCheckoutForm" class="w-full">
      <div>
        <checkout-form
          v-show="!orderSent"
          @cancel="closeCheckoutModal"
          @order-sent="orderSent = true"
        />
        <checkout-confirmed
          v-show="orderSent"
          @close="endCheckout"
          @cancel="toggle"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import BButton from "../BButton";
import BIcon from "../BIcon";
import BModal from "../BModal";
import CheckoutConfirmed from "./CheckoutConfirmed";
import CheckoutForm from "./CheckoutForm";
import ShoppingCartItem from "./ShoppingCartItem";
import * as checkoutMutations from "@/store/modules/checkout/mutation-types";
import money from "@/utils/money";

export default {
  name: "ShoppingCart",

  components: {
    BButton,
    BIcon,
    BModal,
    CheckoutConfirmed,
    CheckoutForm,
    ShoppingCartItem
  },

  data() {
    return {
      showConfimation: false,
      showCheckoutForm: false,
      orderSent: false
    };
  },

  computed: {
    isEmpty() {
      return !this.items.length;
    },
    total() {
      return this.$store.getters["checkout/getTotal"];
    },
    formattedTotal() {
      return money.format(this.total);
    },
    items() {
      return this.$store.state.checkout.items;
    },
    showShoppingCart() {
      return this.$store.state.checkout.showShoppingCart;
    }
  },

  watch: {
    showCheckoutForm() {
      if (this.orderSent) {
        this.orderSent = false;
      }
    }
  },

  methods: {
    closeCheckoutModal() {
      this.showCheckoutForm = false;
    },
    endCheckout() {
      this.orderSent = false;
      this.showCheckoutForm = false;
      this.$store.commit(
        `checkout/${checkoutMutations.SHOW_SHOPPING_CART}`,
        false
      );
    },
    toggle() {
      this.$store.commit(
        `checkout/${checkoutMutations.SHOW_SHOPPING_CART}`,
        !this.showShoppingCart
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.shopping-cart {
  top: 0em;
  right: 0em;
  padding: 1em;

  &.show {
    background-color: rgba(20, 20, 20, 0.5);
  }
}
</style>
