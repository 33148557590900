<template>
  <div class="p-6 text-center bg-white">
    <div class="mb-4 text-4xl">🎉</div>
    <div class="font-black text-lg mb-4">{{ $t("thank_you") }}!</div>
    <div class="mb-4">
      {{ $t("if_your_order_was_not_sent_click_send_again") }}
    </div>
    <b-button @click="sendAgain" class="mr-3">
      {{ $t("send_again") }}
    </b-button>
    <b-button @click="close" variant="secondary">
      {{ $t("close") }}
    </b-button>
  </div>
</template>

<script>
import BButton from "@/components/BButton";

export default {
  name: "CheckoutConfirmed",

  components: {
    BButton
  },

  methods: {
    sendAgain() {
      this.$store.dispatch("checkout/sendOrder");
    },
    close() {
      this.$store.dispatch("checkout/cleanShoppingCart").then(() => {
        this.$emit("close");
      });
    }
  }
};
</script>
