<template>
  <div class="markup-content text-center">
    <div v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "MarkupContent",

  props: {
    content: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.markup-content {
  img {
    display: inline !important;
  }
}
</style>
