import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BButton from "@/components/BButton";
import "./styles/vendor/tailwind/index.css";
import "./styles/index.scss";
import "./plugins";
import i18n from "./i18n";
import "./registerServiceWorker";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;

Vue.component("b-button", BButton);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBemKlQe7B9AcUqSjtnZTBDdvjoqBgck7s",
    libraries: "visualization"
    // v: '3.26',
  }
});
Vue.component("google-map", VueGoogleMaps.Map);
Vue.component("google-marker", VueGoogleMaps.Marker);

if (process.env.VUE_APP_MODE === "pwa") {
  import("./registerServiceWorker");
}

if (process.env.VUE_APP_MODE === "widget") {
  const vueCustomElement = import("vue-custom-element");
  import("document-register-element/build/document-register-element");
  Vue.use(vueCustomElement);
  App.store = store;
  Vue.customElement("biew-widget", App, {
    beforeCreateVueInstance(RootComponentDefinition) {
      RootComponentDefinition.i18n = i18n;
      return RootComponentDefinition;
    }
  });
} else {
  store.dispatch("setConfig");
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount("#app");
}
